import { Box, Container, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import mainTheme from "../css/themes/mainTheme";
import CopyrightText from "./copyrightText";
import VersionText from "./versionText";

function EmptyFeedback({location, screen}) {
  let textSizing = 0
  let mainText
  let subText
  switch (location) {
    case 'view':
      mainText = `No view set to screen ${screen+1}`;
      subText = 'If there is meant to be a view playing on this screen, please check if your playlist is set up correctly in the portal.';
      textSizing = 2;
      break;
    case 'playlist':
      mainText = `No playlist set in today's schedule`;
      subText = 'If there is meant to be a playlist playing today, please check if your schedule is set up correctly in the portal.';
      break;
    case 'schedule':
      mainText = `No schedule set for today`;
      subText = 'If there is meant to be a schedule active for today, please check if your device is set up correctly in the portal.';
      break;
    default:
      mainText = `Something went wrong`;
      subText = 'Please check the portal if your device is setup correctly.';
      break;
  }
  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            position:'relative',
            height: '100%', // Ensure this Box takes up the full height of its parent,
            width: '100%'
          }}
        >
          <Container component="main" fixed >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <Box sx={{ marginBottom: 10 }}>
                <Typography variant={`h${3+textSizing}`} gutterBottom>
                  {mainText}
                </Typography>
              </Box>
              <Box>
                <Typography variant={`h${6+textSizing}`}>
                  {subText}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: 'absolute', right: '0', bottom: '0', padding: 2 }}>
              <VersionText />
            </Box>
            <Box sx={{ position: 'absolute', left: '0', bottom: '0', padding: 2 }}>
              <CopyrightText />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default EmptyFeedback;
