import { Box } from "@mui/material";

export default function GridItem ({column, row, children}) {
    return(
        <Box sx={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            gridColumn: column, 
            gridRow: row,
            overflow: 'hidden'
        }}>
            {children}
        </Box>
    )
}