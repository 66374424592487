import { useEffect } from "react";
import YouTube from "react-youtube";

let loopTimeout
const onReady = event => {
    event.target.playVideo();
}
const onPause = () => {
    clearTimeout(loopTimeout);
}
const onPlay = (event, view) => {
    if (event.target.getPlayerState() === 1) {
        let loopDuration = event.target.getDuration() - 0.5;
        if (loopDuration > view.seconds) {
            loopDuration = view.seconds
        }
        loopTimeout = setTimeout(()=>{
            event.target.seekTo(0);
        }, (loopDuration - event.target.getCurrentTime()) * 1000)
    }
}
export default function YoutubePlayer({view}) {
    const options = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoPlay: 1,
            mute: 1,
            controls: 0,
            rel: 0,
            playsinline: 0
        },
    };

    useEffect(() => {
        return () => {
            clearTimeout(loopTimeout)
        }
    }, [])
    
    return(
        <YouTube style={{display: "flex", height: '100%', width: '100%'}} videoId={view.youtube_url.split("=")[1]} opts={options} onReady={onReady} onPause={onPause} onPlay={(event) => {onPlay(event, view)}}/>
    )
}